import {
  createFullPath,
  getPartOrAssemblyProperties,
  ProductDefinition,
  ProductDefinitionOutput,
  ProductDefinitionParameterDefault,
  runPublishChecks,
  toProductDefinitionInputParameter,
} from 'mid-addin-lib';
import Skeleton from '@mui/material/Skeleton';
import { OutputType, OutputTypes } from '@adsk/offsite-dc-sdk';
import text from 'inventor.text.json';
import commonText from '@mid-react-common/common/common.text.json';
import { ExpandableTextSection, FlexContainer, SummaryTableRow, midTheme } from '@mid-react-common/common';
import { uniq } from 'lodash';
import WarningIcon from '@mui/icons-material/Warning';
import { MetaInfoPath } from 'mid-types';
import { codeRunner } from '@adsk/informed-design-code-runner';
import { currentRuleKey } from 'components/Rules/InputCodeblocks/InputCodeblocks.constants';
import { InventorDataStore } from 'context/DataStore/InventorDataStore';

const SLASH = ' / ';

export interface OutputsSummaryTableData {
  outputType: string | JSX.Element;
  fileTypes: string | JSX.Element;
  representations: string | JSX.Element;
}

const extractRFAOutputType = (currentProductDefinition: Partial<ProductDefinition>): ProductDefinitionOutput | undefined =>
  currentProductDefinition.outputs?.filter((output) => output.type === OutputType.RFA)[0];

const extractRfaModelStates = (currentProductDefinition: Partial<ProductDefinition>): string | undefined =>
  currentProductDefinition.outputs
    ?.reduce((acc: string[], output) => {
      if (output.type === OutputType.RFA && output.options?.modelStates) {
        return [...acc, ...output.options.modelStates];
      }
      return acc;
    }, [])
    .join(', ');

export const getProductDefinitionSummaryData = (
  currentProductDefinition: Partial<ProductDefinition>,
  enableStaticContent: boolean,
): SummaryTableRow[] => {
  const parameters = currentProductDefinition.inputs;
  return [
    {
      title: text.publishProductDefinitionName,
      value: currentProductDefinition.name || <Skeleton />,
    },
    {
      title: text.sourceContentBaseModel,
      value: currentProductDefinition.assembly || <Skeleton />,
    },
    {
      title: text.productDefinitionPublishedAs,
      value: currentProductDefinition.isConfigurable
        ? text.publishValidation.publishedAsConfigurable
        : text.publishValidation.publishedAsStatic,
      hidden: !enableStaticContent,
    },
    {
      title: text.publishProductDefinitionParametersCount,
      value: parameters?.length.toString() || <Skeleton />,
    },
    {
      title: text.publishProductDefinitionPropertiesCount,
      value:
        currentProductDefinition.inputs && parameters ? (
          (currentProductDefinition.inputs.length - parameters.length).toString()
        ) : (
          <Skeleton />
        ),
    },
  ];
};

export const getRFASummaryData = (currentProductDefinition: Partial<ProductDefinition>): SummaryTableRow[] => [
  {
    title: text.publishProductDefinitionRevitCategory,
    value: extractRFAOutputType(currentProductDefinition)?.options?.category || <Skeleton />,
  },
  {
    title: text.publishProductDefinitionRevitFamily,
    value: extractRFAOutputType(currentProductDefinition)?.options?.family || <Skeleton />,
  },
  {
    title: text.publishRepresentations,
    value: extractRfaModelStates(currentProductDefinition) || <Skeleton />,
  },
];

const getFullPublishLocationPath = (accountName: string, projectName: string, folder: MetaInfoPath): string => {
  const publishLocationPathParts = [
    accountName,
    projectName,
    ...folder.parentPath.map((parentPath) => parentPath.name),
    folder.name,
  ];
  return publishLocationPathParts.join(' / ');
};

type GetReleaseInfoSummaryData = {
  publishedProductName: string;
  currentProductDefinition: Partial<ProductDefinition>;
  release?: number;
  hasFailed?: boolean;
  notes?: string;
};

export const getReleaseInfoSummaryData = ({
  publishedProductName,
  currentProductDefinition,
  release,
  hasFailed,
  notes,
}: GetReleaseInfoSummaryData): SummaryTableRow[] => {
  const releaseInfoData: SummaryTableRow[] = [];
  if (hasFailed) {
    releaseInfoData.push({
      title: text.publishRelease,
      value: (
        <FlexContainer alignItems="center" gap={midTheme.var.marginBase}>
          <WarningIcon color="error" /> {text.publishNotPublished}
        </FlexContainer>
      ),
    });
  } else if (release) {
    releaseInfoData.push({
      title: text.publishRelease,
      value: release.toString() || <Skeleton />,
    });
  }

  return [
    {
      title: text.publishReleaseName,
      value: publishedProductName || <Skeleton />,
    },
    ...releaseInfoData,
    {
      title: text.publishLocationTitle,
      value:
        currentProductDefinition.account && currentProductDefinition.project && currentProductDefinition.folder ? (
          getFullPublishLocationPath(
            currentProductDefinition.account.name,
            currentProductDefinition.project.name,
            currentProductDefinition.folder,
          )
        ) : (
          <Skeleton />
        ),
    },
    {
      title: text.publishReleaseNotesTitle,
      value: (
        <ExpandableTextSection
          className={!notes ? 'mid-status-secondary' : ''}
          content={notes || commonText.releaseNotes.notesUnspecified}
        />
      ),
    },
  ];
};

export const getProductOutputsSummaryData = (
  currentProductDefinition: Partial<ProductDefinition>,
): OutputsSummaryTableData[] => {
  const outputs = currentProductDefinition.outputs;
  const summaryData = [];

  // Model 3d
  if (outputs && outputs.some((output) => output.type === OutputType.IAM)) {
    summaryData.push({
      outputType: text.outputsInventorModel,
      fileTypes: text.publishIamFileTypes,
      representations: text.publishAll,
    });
  }

  // Drawings 2d
  const drawing2dFormats: OutputTypes[] = [OutputType.DWG, OutputType.IDW, OutputType.PDF];
  const availableDrawings = outputs?.filter((output) => drawing2dFormats.includes(output.type));

  if (outputs && availableDrawings && availableDrawings.length) {
    const availableDrawingsTypes = [];
    if (availableDrawings.some((output) => output.type === OutputType.DWG)) {
      availableDrawingsTypes.push(`.${text.outputsDWG.toLowerCase()}`);
    }
    if (availableDrawings.some((output) => output.type === OutputType.IDW)) {
      availableDrawingsTypes.push(`.${text.outputsIDW.toLowerCase()}`);
    }
    if (availableDrawings.some((output) => output.type === OutputType.PDF)) {
      availableDrawingsTypes.push(`.${text.outputsPDF.toLowerCase()}`);
    }

    summaryData.push({
      outputType: text.outputsDrawing2D,
      fileTypes: availableDrawingsTypes.join(SLASH),
      representations: text.publishUnspecified,
    });
  }

  // Bill of materials
  const availableBoms = outputs?.filter((output) => output.type === OutputType.BOM);
  if (outputs && availableBoms && availableBoms.length) {
    const bomRepresentations = availableBoms.map((output) => output.options?.modelStates?.join(SLASH));
    summaryData.push({
      outputType: text.outputsBillOfMaterials,
      fileTypes: `.${text.outputsCSV.toLowerCase()}`,
      representations: bomRepresentations.join(SLASH),
    });
  }

  //Neutral Type
  const neutralFormats: OutputTypes[] = [OutputType.SAT, OutputType.STEP, OutputType.GLB];
  const availableNeutralFormats = outputs?.filter((output) => neutralFormats.includes(output.type));
  if (outputs && availableNeutralFormats && availableNeutralFormats.length) {
    const availableNeutralFormatTypes = [];
    if (availableNeutralFormats.some((output) => output.type === OutputType.SAT)) {
      availableNeutralFormatTypes.push(`.${text.outputsSAT.toLowerCase()}`);
    }
    if (availableNeutralFormats.some((output) => output.type === OutputType.STEP)) {
      availableNeutralFormatTypes.push(`.${text.outputsSTEP.toLowerCase()}`);
    }
    if (availableNeutralFormats.some((output) => output.type === OutputType.GLB)) {
      availableNeutralFormatTypes.push(`.${text.outputsGLB.toLowerCase()}`);
    }

    const availableNeutralFormatsModelStates = availableNeutralFormats.reduce<string[]>((acc, output) => {
      if (output.options?.modelStates) {
        return [...acc, ...output.options.modelStates];
      }
      return acc;
    }, []);

    const uniqueModelStates = uniq(availableNeutralFormatsModelStates);

    summaryData.push({
      outputType: text.outputsNeutralFormat,
      fileTypes: availableNeutralFormatTypes.join(SLASH),
      representations: uniqueModelStates.join(SLASH),
    });
  }

  if (!summaryData.length) {
    return [
      {
        outputType: <Skeleton />,
        fileTypes: <Skeleton />,
        representations: <Skeleton />,
      },
    ];
  }

  return summaryData;
};

const productDefinitionDefaultValuesCheck = (
  currentProductDefinition: ProductDefinition,
  allParameterDefaults: Map<string, ProductDefinitionParameterDefault>,
): boolean => {
  const productDefinitionDefaultValuesError = !!currentProductDefinition.inputs.find((input) => {
    const parameterDefault = allParameterDefaults.get(input.name);
    if (parameterDefault) {
      return parameterDefault.value !== input.value;
    }
    return false;
  });
  return productDefinitionDefaultValuesError;
};

const inventorModelDefaultValuesCheck = async (
  currentProductDefinition: ProductDefinition,
  allParameterDefaults: Map<string, ProductDefinitionParameterDefault>,
): Promise<boolean> => {
  const fullPath = createFullPath(currentProductDefinition.topLevelFolder, currentProductDefinition.assembly);
  const inventorModelParameters = (await getPartOrAssemblyProperties(fullPath)).parameters;
  const inventorModelDefaultValuesError = !!inventorModelParameters.find((input) => {
    const parameterDefault = allParameterDefaults.get(input.name);
    if (parameterDefault) {
      const convertedInput = toProductDefinitionInputParameter(input);
      return parameterDefault.value !== convertedInput.value;
    }
    return false;
  });

  return inventorModelDefaultValuesError;
};

const formRulesCheck = async (currentProductDefinition: ProductDefinition): Promise<boolean> => {
  // Rules Check - Only applicable to Configurable Product Definitions
  const rule = currentProductDefinition.rules.find((rule) => rule.key === currentRuleKey);
  const { error } = await codeRunner({ code: rule?.code || '', inputs: currentProductDefinition.inputs });
  return !!error;
};

export const checkPublishPrerequisitesForConfigurableProductDefinition = async (
  currentProductDefinition: ProductDefinition,
): Promise<InventorDataStore['publishPrerequisiteErrors'] | null> => {
  const allParameterDefaults = new Map(currentProductDefinition.parametersDefaults?.map((param) => [param.name, param]));
  // Product Definition Default Values Check
  const productDefinitionDefaultValuesError = productDefinitionDefaultValuesCheck(
    currentProductDefinition,
    allParameterDefaults,
  );
  // Inventor Model Default Values Check
  const inventorModelDefaultValuesError = await inventorModelDefaultValuesCheck(
    currentProductDefinition,
    allParameterDefaults,
  );
  const { isDocumentDirty: documentSavedError } = await runPublishChecks();

  const formError = await formRulesCheck(currentProductDefinition);

  // Go to summary page if there are errors
  if (formError || productDefinitionDefaultValuesError || inventorModelDefaultValuesError || documentSavedError) {
    return {
      formError,
      productDefinitionDefaultValuesError,
      inventorModelDefaultValuesError,
      documentSavedError,
    };
  }
  return null;
};

export const checkPublishPrerequisitesForStaticProductDefinition = async (
  currentProductDefinition: ProductDefinition,
): Promise<InventorDataStore['publishPrerequisiteErrors'] | null> => {
  // Static Product Definition with no adopted parameters - no checks should be applied
  if (!currentProductDefinition.inputs.length) {
    return null;
  }

  // Static Product Definition with adopted parameters
  const allParameterDefaults = new Map(currentProductDefinition.parametersDefaults?.map((param) => [param.name, param]));
  // Product Definition Default Values Check
  const productDefinitionDefaultValuesError = productDefinitionDefaultValuesCheck(
    currentProductDefinition,
    allParameterDefaults,
  );
  // Inventor Model Default Values Check
  const inventorModelDefaultValuesError = await inventorModelDefaultValuesCheck(
    currentProductDefinition,
    allParameterDefaults,
  );
  const { isDocumentDirty: documentSavedError } = await runPublishChecks();

  if (productDefinitionDefaultValuesError || inventorModelDefaultValuesError || documentSavedError) {
    return {
      // The formError field is not relevant for static content
      formError: null,
      productDefinitionDefaultValuesError,
      inventorModelDefaultValuesError,
      documentSavedError,
    };
  }

  return null;
};

export const checkPublishPrerequisites = (
  currentProductDefinition: ProductDefinition,
): Promise<InventorDataStore['publishPrerequisiteErrors'] | null> => {
  return currentProductDefinition.isConfigurable
    ? checkPublishPrerequisitesForConfigurableProductDefinition(currentProductDefinition)
    : checkPublishPrerequisitesForStaticProductDefinition(currentProductDefinition);
};
