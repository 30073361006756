import { analytics, ANALYTICS_ACTIONS, NOTIFICATION_STATUSES, NotificationContext } from '@mid-react-common/common';
import { useMutation } from '@tanstack/react-query';
import TabProgressContext from 'context/TabProgressStore/TabProgress.context';
import { TABS } from 'context/TabProgressStore/tabProgressStore';
import text from 'inventor.text.json';
import { isUndefined } from 'lodash';
import { ProductDefinitionsCrudUtils } from 'mid-addin-lib';
import { useContext, useEffect, useRef } from 'react';
import { TabProgress } from 'types';
import { productDefinitionActions, useProductDefinitionStore } from '../../../context/DataStore/productDefinitionStore';

type UseProductDefinitionConfigurationHeaderState = {
  readyToPublish: boolean;
  saveProductDefinitionLoading: boolean;
  handleSaveProductDefinition: () => void;
};

export const useProductDefinitionConfigurationHeader = (): UseProductDefinitionConfigurationHeaderState => {
  const productDefinitionRef = useRef(useProductDefinitionStore.getState());

  // We use a reference because we don't want to react when these change
  useEffect(() => {
    useProductDefinitionStore.subscribe((state) => {
      productDefinitionRef.current = state;
    });
  }, []);

  const { tabProgress } = useContext(TabProgressContext);
  const { showNotification, logAndShowNotification } = useContext(NotificationContext);

  const readyToPublish =
    tabProgress[TABS.SOURCE_CONTENT] === TabProgress.COMPLETED &&
    tabProgress[TABS.INPUTS] === TabProgress.COMPLETED &&
    tabProgress[TABS.RULES] === TabProgress.COMPLETED &&
    tabProgress[TABS.OUTPUTS] === TabProgress.COMPLETED;

  const { isPending: saveProductDefinitionLoading, mutateAsync: saveProductDefinition } = useMutation({
    mutationFn: ProductDefinitionsCrudUtils.upsertProductDefinition,
  });

  const handleSaveProductDefinition = async () => {
    try {
      const savedProductDefinition = await saveProductDefinition(productDefinitionRef.current);
      if (!savedProductDefinition) {
        return;
      }

      // Segment Event
      // We only send the event when is the first time we save the product definition
      if (productDefinitionHasNeverBeenSaved(productDefinitionRef.current.id) && savedProductDefinition.id) {
        analytics.track(ANALYTICS_ACTIONS.IVTW.createProductDefinition, {
          productDefinitionId: savedProductDefinition.id,
          productDefinitionName: savedProductDefinition.name,
          numberOfInputs: savedProductDefinition.inputs.length,
          hasRules: savedProductDefinition.rules.length > 0,
          hasCodeBlocksWorkspace: !!savedProductDefinition.codeBlocksWorkspace,
          numberOfOutputs: savedProductDefinition.outputs.length,
          numberOfDrawingThumbnails: savedProductDefinition.drawingThumbnails?.length || 0,
          releaseName: savedProductDefinition.releaseName,
        });
      }

      productDefinitionActions.setProductDefinition({
        ...savedProductDefinition,
      });
      showNotification({
        message: text.notificationSavedProductDefinitionSuccess,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    } catch (error) {
      logAndShowNotification({
        error,
        message: text.notificationSavedProductDefinitionFailed,
      });
    }
  };

  return {
    readyToPublish,
    saveProductDefinitionLoading,
    handleSaveProductDefinition,
  };
};

const productDefinitionHasNeverBeenSaved = (id?: string | undefined): boolean => isUndefined(id) || id === '';
