import Box from '@mui/material/Box';
import { LocationContainer, Spacing } from '@mid-react-common/common';
import text from 'inventor.text.json';
import Typography from '@mui/material/Typography';
import {
  codeBlocksBestPracticesLink,
  parameterAdoptionLink,
  publishingPrerequisitesChecksLink,
  staticVsConfigurableLink,
  syncingAndSavingLink,
} from './constants';
import ValidationSection from './ValidationSection';
import { InventorDataStore, useInventorDataStore } from 'context/DataStore/InventorDataStore';
import { PrerequisitesWrapper } from '../Publishing.styles';
import { useShallow } from 'zustand/react/shallow';
import { publishingInvalidtestIds } from 'tests/helpers/dataTestIds';

const publishValidationText = text.publishValidation;

interface PublishingInvalidProps {
  productDefinitionIsConfigurable: boolean | undefined;
}

const getPrerequisiteCheckResultsForConfigurableProduct = (
  publishPrerequisiteErrors: InventorDataStore['publishPrerequisiteErrors'],
) => {
  return (
    <>
      <ValidationSection
        title={publishValidationText.formValuesCheck.title}
        description={publishValidationText.formValuesCheck.description}
        isComplete={!publishPrerequisiteErrors.formError}
        resources={[
          {
            label: publishValidationText.helpLink,
            url: publishingPrerequisitesChecksLink,
          },
          {
            label: publishValidationText.bestPracticesLink,
            url: codeBlocksBestPracticesLink,
          },
        ]}
      />
      <ValidationSection
        title={publishValidationText.productDefinitionDefaultsCheck.title}
        description={publishValidationText.productDefinitionDefaultsCheck.description}
        isComplete={!publishPrerequisiteErrors.productDefinitionDefaultValuesError}
        resources={[
          {
            label: publishValidationText.helpLink,
            url: publishingPrerequisitesChecksLink,
          },
          {
            label: publishValidationText.parameterAdoptionLink,
            url: parameterAdoptionLink,
          },
          {
            label: publishValidationText.bestPracticesLink,
            url: codeBlocksBestPracticesLink,
          },
        ]}
      />
      <ValidationSection
        title={publishValidationText.inventorModelValuesCheck.title}
        description={publishValidationText.inventorModelValuesCheck.description}
        isComplete={!publishPrerequisiteErrors.inventorModelDefaultValuesError}
        resources={[
          {
            label: publishValidationText.helpLink,
            url: publishingPrerequisitesChecksLink,
          },
          {
            label: publishValidationText.syncingAndSavingLink,
            url: syncingAndSavingLink,
          },
        ]}
      />
      <ValidationSection
        title={publishValidationText.inventorDataSetSavedCheck.title}
        description={publishValidationText.inventorDataSetSavedCheck.description}
        isComplete={!publishPrerequisiteErrors.documentSavedError}
        resolutionSteps={[
          publishValidationText.inventorDataSetSavedCheck.step1,
          publishValidationText.inventorDataSetSavedCheck.step2,
          publishValidationText.inventorDataSetSavedCheck.step3,
        ]}
      />
    </>
  );
};

const getPrerequisiteCheckResultsForStaticProduct = (
  publishPrerequisiteErrors: InventorDataStore['publishPrerequisiteErrors'],
) => {
  return (
    <>
      <ValidationSection
        title={publishValidationText.productDefinitionDefaultsCheck.title}
        description={publishValidationText.productDefinitionDefaultsCheck.description}
        isComplete={!publishPrerequisiteErrors.productDefinitionDefaultValuesError}
        resources={[
          {
            label: publishValidationText.helpLink,
            url: publishingPrerequisitesChecksLink,
          },
          {
            label: publishValidationText.parameterAdoptionLink,
            url: parameterAdoptionLink,
          },
          {
            label: publishValidationText.staticVsConfigurableProductsLink,
            url: staticVsConfigurableLink,
          },
        ]}
      />
      <ValidationSection
        title={publishValidationText.inventorModelValuesCheck.title}
        description={publishValidationText.inventorModelValuesCheck.description}
        isComplete={!publishPrerequisiteErrors.inventorModelDefaultValuesError}
        resources={[
          {
            label: publishValidationText.helpLink,
            url: publishingPrerequisitesChecksLink,
          },
          {
            label: publishValidationText.staticVsConfigurableProductsLink,
            url: 'https://help.autodesk.com/view/AVRF/ENU/?guid=AVRF_Workflows_static_vs_config_products_html',
          },
        ]}
      />
      <ValidationSection
        title={publishValidationText.inventorDataSetSavedCheck.title}
        description={publishValidationText.inventorDataSetSavedCheck.description}
        isComplete={!publishPrerequisiteErrors.documentSavedError}
        resolutionSteps={[
          publishValidationText.inventorDataSetSavedCheck.step1,
          publishValidationText.inventorDataSetSavedCheck.step2,
          publishValidationText.inventorDataSetSavedCheck.step3,
        ]}
      />
    </>
  );
};

const PublishingInvalid: React.FC<PublishingInvalidProps> = ({ productDefinitionIsConfigurable }): JSX.Element => {
  const { publishPrerequisiteErrors } = useInventorDataStore(
    useShallow((state) => ({
      publishPrerequisiteErrors: state.publishPrerequisiteErrors,
      productDefinitionHasUnsavedChanges: state.productDefinitionHasUnsavedChanges,
    })),
  );

  const results = productDefinitionIsConfigurable
    ? getPrerequisiteCheckResultsForConfigurableProduct(publishPrerequisiteErrors)
    : getPrerequisiteCheckResultsForStaticProduct(publishPrerequisiteErrors);

  return (
    <LocationContainer className="mid-bg-shadow">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="h2" fontWeight="bold" gutterBottom data-testid={publishingInvalidtestIds.incompleteReqsTitle}>
          {publishValidationText.resolveRequirements}
        </Typography>
        <Spacing />
        <PrerequisitesWrapper>{results}</PrerequisitesWrapper>
      </Box>
    </LocationContainer>
  );
};

export default PublishingInvalid;
