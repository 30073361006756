export const publishingPrerequisitesChecksLink =
  'https://help.autodesk.com/view/AVRF/ENU/?guid=AVRF_Get_Started_inventor_inv_publish_prereqs_html';

export const codeBlocksBestPracticesLink =
  'https://help.autodesk.com/view/AVRF/ENU/?guid=AVRF_Workflows_best_practices_codeblocks_html';

export const parameterAdoptionLink = 'https://help.autodesk.com/view/AVRF/ENU/?guid=AVRF_HelpTopics_3_param_adoption_html';

export const syncingAndSavingLink =
  'https://help.autodesk.com/view/AVRF/ENU/?guid=AVRF_HelpTopics_5_sync_save_prod_def_base_model_html';

export const staticVsConfigurableLink =
  'https://help.autodesk.com/view/AVRF/ENU/?guid=AVRF_Workflows_static_vs_config_products_html';
